// Declare variables 
var photos = new Array(5);						// Create a new array called photos with 5 slots
var frame = 0;									// Integer variable called frame to keep track of the current photo being shown
var control = setInterval("animate()", 5000);	// Variable to call the animate() function every 5 seconds

// Function to load the slideshow
function slideshowInit() {
	imageLoad();	// Call the imageLoad() function
	animate();		// Call the animate() function
}

// Function to preload the images ready for use
function imageLoad() {
	for(i=0; i<=4; i++) {										// Loop five times
		photos[i] = new Image();								// Add a new image object to the photos array
		photos[i].src = "img/slideshow/photo_" + i + ".png";	// Set the source of the image
	}
}

// Function to show the slideshow controls
function showSlideshowControls() {
	document.getElementById("slideshowOverlay").className = "overlayShow";	// Set the class of slideshowOverlay to overlayShow
	timeout = setTimeout("hideSlideshowControls()", 4000);					// After four seconds hide the controls by calling the hideSlideshowControls() function
}

// Function to hide the slideshow controls
function hideSlideshowControls() {
	document.getElementById("slideshowOverlay").className = "overlayHidden";	// Set the class of slideshowOverlay to overlayHidden
	clearTimeout(timeout);														// Clear the timeout variable to prevent a double call of this function
}

// Function to play or pause the slideshow
function playPause() {
	var playPause = document.controlPlayPause;			// Create a variable that allows us to access the image element with the name controlPlayPause
	if(playPause.alt == "controlPause"){				// If the alternative text equals controlPause
		clearInterval(control);							// Clear the control variable and stop the slideshow
		playPause.alt = "controlPlay";					// Set the alternative text of the image to controlPlay
		playPause.src = "img/slideshowPlay.png";		// Set the source of the image to the play button
	} else {											
		control = setInterval("animate()", 5000);		// Set the control variable to call the animate() function every 5 seconds
		playPause.alt = "controlPause";					// Set the alternative text of the image to controlPause
		playPause.src = "img/slideshowPause.png";		// Set the source of the image to the pause button
	}
}

// Function to show the next image
function nextImage() {
	var playPause = document.controlPlayPause;			// Create a variable that allows us to access the image element with the name controlPlayPause
	clearInterval(control);								// Clear the control variable and stop the slideshow
	if(playPause.alt != "controlPlay"){					// If the alternative text does not equal controlPlay
		control = setInterval("animate()", 5000);		// Set the control variable to call the animate() function every 5 seconds
	}
	animate();											// Call the animate() function
}

// Function to show the previous image
function lastImage() {
	var playPause = document.controlPlayPause;		// Create a variable that allows us to access the image element with the name controlPlayPause
	clearInterval(control);							// Clear the control variable and stop the slideshow
	frame = frame - 2;								// Get the current frame and minus 2
	if (frame == -1) { frame = 4; }					// If frame equals -1 set the frame to 4
	if (frame == -2) { frame = 3; }					// If frame equals -2 set the frame to 3
	if(playPause.alt != "controlPlay"){				// If the alternative text does not equal controlPlay
		control = setInterval("animate()", 5000);	// Set the control variable to call the animate() function every 5 seconds
	}
	animate();										// Call the animate() function
}

// Function to change the slideshow image
function animate() {
	document.slideshowImage.className = "";					// Clear the class of slideshowImage
    setTimeout(function() {									// Delay the fade in by 1 second to allow for the current image to fade out
        document.slideshowImage.src = photos[frame].src;	// Set the source of slideshowImage to the next image
        document.slideshowImage.className = "fadeIn";		// Set the class of slideshowImage to fadeIn
    },1000);					
    frame++;												// Add one to frame
    if (frame == photos.length) { frame = 0; }				// If frame equals the length of the photos array then set frame to 0
}